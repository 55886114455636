<template>
    <div ref="append-article">
        <zw-sidebar @shown="shown" :title="$t('bank.title.assign_bank_transfer')">
            <div v-if="!loading" class="px-3 py-2">
                <validation-observer tag="div" ref="observer">
                    <b-row>
                        <b-col>
                            <zw-table ref="table"
                                      tableName="debit_bank_transfers"
                                      columnsPrefix="bank_transfers.column."
                                      base-table="bank_transactions"
                                      :customSource="getThis()"
                                      :items="myProvider"
                                      :sticky="false"
                                      sticky-header="100%"
                                      :fields="fields"
                                      :filter="filter"
                                      :selected="selected"
                                      :actions-list="getActions()"
                            >
                                <template #cell(actions)="row">
                                    <b-dropdown class="mb-1"
                                                left
                                                variant="primary"
                                                size="sm"
                                    >
                                        <template #button-content>
                                            {{ $t('common.label.actions') }}
                                        </template>
                                        <b-dropdown-item
                                            @click="assignSingle(row.item.id)"
                                        >
                                            {{ $t('bank.actions.assign') }}
                                        </b-dropdown-item>
                                        <b-dropdown-item
                                            v-if="row.item.assigned"
                                            @click="unlink(row.item.id)"
                                        >
                                            {{ $t('bank.actions.unlink') }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </template>
                            </zw-table>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button :disabled="selected.length==0" block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </validation-observer>
            </div>
        </zw-sidebar>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from "@/bundles/erika_common_bundle/mixins/table-date-filter";

export default {
    name: 'AssignBankTransferModal',
    mixins: [commonSave, commonTable, tableDateFilter],
    data() {
        return {
            tableUniqKey: 'assign-bank-transfer',
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {},
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('BankTransfers', ['getTransfersDebitTable']),
        shown() {
            this.$set(this, 'selected', [])
            this.form = JSON.parse(JSON.stringify(this.defaultForm))

            this.loading = false
        },
        getThis() {
            return this
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'BankTransfers/fetchDebitTransfers', 'getTransfersDebitTable');
        },
        unlink(id) {
            this.$store.dispatch('BankTransfers/unlinkDebit', {
                id: id,
                incoming_invoice_id: this.payload.incoming_invoice_id
            }).finally(() => {
                this.refreshTable()
            })
        },
        assignSingle(id) {
            this.form.ids = [id]
            this.form.incoming_invoice_id = this.payload.incoming_invoice_id

            this.$store.dispatch('BankTransfers/assignToIncomingInvoice', this.form)
                .then((response) => {
                    this.refreshTable()
                })
                .catch(errors => {
                    this.$refs['observer'].setErrors(errors)
                    this.showValidationError()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.form.ids = this.selected.map(row => row.id)
                    this.form.incoming_invoice_id = this.payload.incoming_invoice_id

                    this.$store.dispatch('BankTransfers/assignToIncomingInvoice', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        getActions() {
            return []
        }
    },
}
</script>